<script>
import tablePageTreeBase from '../../../../components/table_page_tree_base';
import TablePage from './table';

export default {
  extends: tablePageTreeBase,
  components: {
    TablePage,
  },
  data() {
    return {
      placeholder: '请输入搜索的客户组织架构',
      title: '客户组织架构',
      restful: '/mdm/baseLazyTreeController/customerOrgLazyTree',
    };
  },
};
</script>
