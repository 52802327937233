var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "grid-content" },
      [
        _c("el-input", {
          attrs: { placeholder: "请输入搜索的组织" },
          model: {
            value: _vm.filterText,
            callback: function ($$v) {
              _vm.filterText = $$v
            },
            expression: "filterText",
          },
        }),
        _vm.showTree
          ? _c("el-tree", {
              ref: "treeRef",
              staticClass: "filter-tree",
              attrs: {
                data: _vm.treeData,
                props: _vm.defaultProps,
                "highlight-current": "",
                "default-expand-all": _vm.defaultExpandAll,
                "expand-on-click-node": false,
              },
              on: { "node-click": _vm.handleCheckChange },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "dialog-footer" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "small", icon: "el-icon-check" },
            on: {
              click: function ($event) {
                return _vm.$emit("submit", _vm.codes)
              },
            },
          },
          [_vm._v("确定 ")]
        ),
        _c(
          "el-button",
          {
            attrs: { type: "danger", size: "small", icon: "el-icon-close" },
            on: {
              click: function ($event) {
                return _vm.$emit("onClose")
              },
            },
          },
          [_vm._v("关闭 ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }