var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "associated_customer" }, [
    _c("div", { staticClass: "associated-title" }, [_vm._v("未关联")]),
    _c(
      "div",
      { staticClass: "associated_customer_down" },
      [
        _c("businessOwnershipOne", {
          ref: "two",
          attrs: {
            propsObj: Object.assign({}, _vm.propsObj, {
              businessOwnership: "two",
            }),
          },
          on: { refresh: _vm.refresh },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "associated_customer_line" }),
    _c("div", { staticClass: "associated-title" }, [_vm._v("已关联")]),
    _c(
      "div",
      { staticClass: "associated_customer_up" },
      [
        _c("businessOwnershipOne", {
          ref: "one",
          attrs: {
            propsObj: Object.assign({}, _vm.propsObj, {
              businessOwnership: "one",
            }),
          },
          on: { refresh: _vm.refresh },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "associated_customer_Btn" }, [
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small", icon: "el-icon-close" },
              on: {
                click: function ($event) {
                  return _vm.$emit("onClose")
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }