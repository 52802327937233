<script>
/** *
 *  客户组织 关联客户   与关联终端在同一文件 根据 btnCode执行 不同逻辑      associated_customers.vue
   * yc
   */

import TablePage from '../../../../../../components/table_page';
import request from '../../../../../../utils/request';
import Org from '../position_maintenance/components';

export default {
  extends: TablePage,
  props: {
    propsObj: {},
  },
  components: {
    Org,
  },
  data() {
    return {
      params: {
        customerOrgCode: this.propsObj.customerOrgCode,
      },
      configs: {
        toolBarConfig: [
          {
            name: '更换客户组织',
            buttonType: '0',
            buttonCode: 'changeOrg',
          },
        ],
        formConfig: {
          item: [
            // {}
          ],
        },
      },
      isCalculateHeight: false,
    };
  },
  methods: {
    // 重写 让其不被覆盖
    setButton() {},
    // 重新 覆盖 按钮事件
    buttonClick({ val }) {
      if (val.buttonCode === 'changeOrg') {
        if (this.selectRow.length > 0) {
          this.modalConfig.title = '更换客户组织';
          this.formName = 'Org';
          this.modalClick({ val }, []);
        } else {
          this.$message.error('请选择数据');
        }
      }
    },
    onSubmit(codes) {
      if (!codes) {
        this.$message('请点击选择');
        return;
      }
      let apiUrl = '';
      let params = {};
      // 客户维护
      if (this.propsObj.btnCode === 'associated_customers') {
        apiUrl = '/mdm/mdmCustomerMsgController/updateOrgCodeByCondition';
        params = {
          customerOrgCode: codes,
          customerCodeList: this.selectRow.map((v) => v.customerCode),
        };
      } else if (this.propsObj.btnCode === 'associated_terminal') {
        // 终端维护
        apiUrl = '/mdm/mdmTerminalController/updateCusOrgCode';
        params = {
          customerOrgCode: codes,
          terminalCodeList: this.selectRow.map((v) => v.terminalCode),
        };
      }
      request.post(apiUrl, params).then((res) => {
        if (res.success) {
          this.closeModal();
          this.getList();
        }
        this.$message.success(res.message);
      });
    },
  },
  created() {
    if (this.propsObj.btnCode === 'associated_customers') {
      this.getConfigList('customer_org_associated_customers', true, true);
    } else if (this.propsObj.btnCode === 'associated_terminal') {
      this.getConfigList('customer_org_associated_terminal', true, true);
    }
  },
  mounted() {},
};
</script>
