<template>
  <div class="associated_customer">
   <div class="associated-title">未关联</div>
    <div class="associated_customer_down">
      <businessOwnershipOne
        ref="two"
        @refresh="refresh"
        :propsObj="{...propsObj,businessOwnership:'two'}"></businessOwnershipOne>
    </div>

    <div class="associated_customer_line">

    </div>
     <div class="associated-title">已关联</div>
    <div class="associated_customer_up">
      <businessOwnershipOne
        ref="one"
        @refresh="refresh"
        :propsObj="{...propsObj,businessOwnership:'one'}"></businessOwnershipOne>
    </div>

    <div class="associated_customer_Btn">
      <div class="dialog-footer">
        <el-button @click="$emit('onClose')" type="danger" size="small" icon="el-icon-close">关闭</el-button>
      </div>

    </div>

  </div>
</template>

<script>
import Form from '../../../../../../components/form';
import businessOwnershipOne from './business_ownership_one.vue';

export default {
  extends: Form,
  components: {
    businessOwnershipOne,
  },
  props: {
    propsObj: {},
  },
  data() {
    return {

    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    // 联动刷新列表
    refresh() {
      this.$refs.one.getList();
      this.$refs.two.getList();
    },
  },

};
</script>

<style scoped lang="less">
  .associated_customer{
    .associated-title{
      font-size: 16px;
      height: 20px;
      line-height: 20px;
      font-weight: 600;
    }
    /*height: 50vh;*/
    .associated_customer_line{
      height: 2px;
      width: 100%;
      background-color: #aaa;
      margin: 30px 0;
    }
    .footer-btn {
      position: absolute;
      bottom: 0;
      right: 10px;

      button {
        width: 100px;
      }
    }
  }

</style>
