<script>
import TablePage from '../../../../../../components/table_page';
import request from '../../../../../../utils/request';

export default {
  extends: TablePage,
  props: {
    propsObj: {},
  },
  components: {
  },
  data() {
    return {
      params: {
        customerOrgCode: this.propsObj.customerOrgCode,
      },
      configs: {
        toolBarConfig: [
          {
            name: this.propsObj.businessOwnership === 'one' ? '移除' : '添加',
            buttonType: '0',
            buttonCode: this.propsObj.businessOwnership === 'one' ? 'delete' : 'add',
          },
        ],
      },
      isCalculateHeight: false,
    };
  },
  methods: {
    // 重写 让其不被覆盖
    setButton() {},
    // 重新 覆盖 按钮事件
    buttonClick({ val }) {
      console.log(3444444, val, this.selectRow);

      const params = {
        customerOrgCode: this.propsObj.customerOrgCode,
        positionCodeList: this.selectRow.map((a) => a.positionCode),
      };
      let apiUrl = '';
      if (val.buttonCode === 'add') {
        apiUrl = '/mdm/mdmPositionCustomerOrgController/add';
      }
      if (val.buttonCode === 'delete') {
        apiUrl = '/mdm/mdmPositionCustomerOrgController/remove';
      }

      request.post(apiUrl, params).then((res) => {
        if (res.success) {
          this.$emit('refresh');
        }
      });

      // if (val.buttonCode === 'changeOrg') {
      //   if (this.selectRow.length > 0) {
      //     this.modalConfig.title = '更换组织';
      //     this.formName = 'Org';
      //     this.modalClick({ val }, []);
      //   } else {
      //     this.$message.error('请选择数据');
      //   }
      // }
    },
    onSubmit(codes) {
      if (!codes) {
        this.$message('请点击选择');
        return;
      }
      const params = {
        orgCode: codes,
        positionCodeList: this.selectRow.map((v) => v.positionCode),
      };
      request.post('/mdm/mdmPositionController/batchUpdatePositionOrg', params).then((res) => {
        if (res.success) {
          this.closeModal();
          this.$emit('refresh');
        }
        this.$message.success(res.message);
      });
    },
  },
  created() {
    // this.getConfigList('customer_org_business_ownership_one', true, true);
    if (this.propsObj.businessOwnership === 'one') {
      console.log(411111, this.propsObj.businessOwnership === 'one');

      this.getConfigList('customer_org_business_ownership_one', true, true);
    } else if (this.propsObj.businessOwnership === 'two') {
      this.getConfigList('customer_org_business_ownership_two', true, true);
    }
  },
  mounted() {},
};
</script>
