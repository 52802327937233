<script>
import TablePage from '../../../../../components/table_page';
import configs from '../data';
import Form from '../form';
import Modal from '../../../../../components/modal';
// 关联客户 与  关联组织
import associatedCustomers from '../associated/associated_customers/associated_customers_box.vue';
// 业务归属
import businessOwnership from '../associated/business_ownership/business_ownership.vue';

export default {
  extends: TablePage,
  components: {
    Modal,
    Form,
    associatedCustomers,
    businessOwnership,
  },
  data() {
    return {
      params: {
        code: '',
      },
      configs,
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
        width: '600px',
        height: '500px',
      },
    };
  },
  props: {
    treeCode: String,
  },
  watch: {
    treeCode(val) {
      console.log(val);
      this.params.code = val;
      this.getList();
    },
  },
  created() {
    this.getConfigList('customer_org_table');
  },
  methods: {
    beforeModalClick({ val }) {
      if (val.code === 'enable' || val.code === 'disable' || val.code === 'delete') {
        this.resetTree();
        return false;
      }
      return true;
    },
    beforeSearchEvent(val) {
      if (val.$event.type === 'reset') {
        this.$emit('reset');
      }
      return true;
    },
    modalClick({ val, row }) {
      if (!this.beforeModalClick({ val, row })) return;
      this.formName = 'Form';
      console.log(val);
      this.formConfig = {};
      if (val.code === 'edit') {
        this.formConfig = {
          ...val,
          ...row,
        };
        this.modalConfig.title = '编辑';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '500px';
        this.openModal();
      } else if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '500px';
        this.openModal();
      } else if (val.code === 'associated_customers') {
        this.formName = 'associatedCustomers';
        this.propsObjInData = { ...row, btnCode: 'associated_customers' };
        this.modalConfig.title = '关联客户';
        this.modalConfig.width = '';
        this.modalConfig.height = '';
        this.openModal();
      } else if (val.code === 'associated_terminal') {
        this.formName = 'associatedCustomers';
        this.propsObjInData = { ...row, btnCode: 'associated_terminal' };
        this.modalConfig.title = '关联终端';
        this.modalConfig.width = '';
        this.modalConfig.height = '';
        this.openModal();
      } else if (val.code === 'business_ownership') {
        console.log(63333333, row);
        this.formName = 'businessOwnership';
        this.propsObjInData = { ...row, btnCode: 'business_ownership' };
        this.modalConfig.title = '业务归属';
        this.modalConfig.width = '';
        this.modalConfig.height = '';
        this.openModal();
      } else if (val.code === 'view') {
        this.formConfig = {
          ...val,
          ...row,
          code: 'view',
          buttonCode: 'view',
        };
        this.modalConfig.title = '查看';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '500px';
        this.openModal();
      }
    },
  },
};
</script>
