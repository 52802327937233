<template>
  <div>
    <associatedCustomers :propsObj='propsObj'></associatedCustomers>
    <div class="dialog-footer">
      <el-button
        type="danger"
        @click="()=>{$emit('onClose')}"
        size="small"
        icon="el-icon-close"
      >关闭
      </el-button>
    </div>
  </div>
</template>

<script>
import associatedCustomers from './associated_customers.vue';

export default {
  name: 'associated_customers_box.vue',
  components: {
    associatedCustomers,
  },
  props: {
    propsObj: {},
  },
};
</script>

<style scoped>

</style>
