<script>
import Form from '../../../../../components/form';
import request from '../../../../../utils/request';

export default {
  extends: Form,
  components: {
  },
  data() {
    return {
      rule: [],
      queryData: [],
    };
  },
  async created() {
    console.log(this.formConfig);
    await this.getFormRule('customer_org_form');
    const parentCode = this.getRule('parentCode');
    parentCode.restful = '/mdm/baseTreeController/customerOrgTree';
    parentCode.headers = { functionCode: 'select-customer-org-up' };
    parentCode.value = null;
    parentCode.restfulParams = {
      enableStatus: '009',
    };
    parentCode.props = {
      ...parentCode.props,
      options: [],
      size: 'small',
      placeholder: '请选择上级组织',
      loading: false,
      clearable: true, // 是否显示清空按钮
      props: {
        parent: 'parentCode', // 父级唯一标识
        value: 'code', // 唯一标识
        label: 'name', // 标签显示
        children: 'children', // 子级
        // additionItem: { name: '无上级组织', code: '' },
      },
    };
    const enableStatus = this.getRule('enableStatus');
    enableStatus.value = '009';
    if (this.formConfig.buttonCode === 'edit' || this.formConfig.buttonCode === 'view') {
      this.init();
    } else {
      this.reload(this.rule);
    }
  },

  mounted() {

  },
  methods: {
    init() {
      const { id } = this.formConfig;
      request.get('/mdm/mdmCusOrgController/query', { id }).then((res) => {
        if (res.success) {
          const { result } = res;
          const parentCode = this.getRule('parentCode');
          if (result.parentCode) {
            parentCode.props.props = {
              parent: 'parentCode', // 父级唯一标识
              value: 'code', // 唯一标识
              label: 'name', // 标签显示
              children: 'children', // 子级
              additionItem: { name: '无上级组织', code: '' },
            };
          } else {
            parentCode.props.props = {
              parent: 'parentCode', // 父级唯一标识
              value: 'code', // 唯一标识
              label: 'name', // 标签显示
              children: 'children', // 子级
            };
          }
          parentCode.validate = [
            {
              required: false,
              message: '请输入参数',
            },
          ];
          parentCode.restfulParams = {
            ...parentCode.restfulParams,
            excludeCodeAndChildren: result.customerOrgCode,
          };
          this.setValue(result);
          this.queryData = result;
        }
      });
    },
    // 提交
    submit() {
      console.log(this.getFormData());
      const formData = this.getFormData();

      if (formData) {
        let url = '/mdm/mdmCusOrgController/add';
        let params = formData;
        if (this.formConfig && this.formConfig.buttonCode === 'edit') {
          url = '/mdm/mdmCusOrgController/edit';
          params = { ...this.queryData, ...formData };
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message.success('操作成功');
            this.$emit('onClose');
            this.$emit('resetTree');
          }
        });
      }
      console.log(formData);
    },
  },
};
</script>
